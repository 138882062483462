export default {
  "general": {
    "appVersion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Версія: ", _interpolate(_named("version"))])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
    "invalidFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недопустимий формат файлу"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса електронної скриньки"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])}
  },
  "history": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортувати в CSV файл"])}
  },
  "home": {
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взаємодії"])},
    "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направлення"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрацьовані документи"])}
  },
  "documents": {
    "consultations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консультації"])},
    "extracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виписки"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клацніть або перетягніть документ(и) для завантаження"])},
    "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрацювати"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути"])},
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обрано документів: ", _interpolate(_named("count"))])},
    "selectEncounterType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть тип взаємодії"])}
  },
  "processing": {
    "document": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опрацювання <b>", _interpolate(_named("name")), "</b> (", _interpolate(_named("number")), "/", _interpolate(_named("count")), ")..."])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роботу завершено."])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає результатів"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ опрацьовано успішно"])},
    "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка при опрацюванні документа"])}
  },
  "referrals": {
    "takeToWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взяти в роботу"])},
    "processed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Взято в роботу направлень: ", _interpolate(_named("count"))])}
  },
  "updates": {
    "findPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук пацієнта"])},
    "createEncounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створення взаємодії"])},
    "addRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання рекомендацій"])},
    "addAdmissionReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання причини звернення"])},
    "addCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання діагнозу"])},
    "addAkmiCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання дій по кодах AKMI"])},
    "addDiagnosticReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання діагностичного звіту по"])},
    "trySelectDigitalReferral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спроба додати електронне направлення"])},
    "changeToUrgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна пріоритету на ургентний"])},
    "addHospitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додавання даних про госпіталізацію"])},
    "getReferralsInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримання списку направлень в черзі"])},
    "noReferralsInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає направлень в роботі"])},
    "takeReferralToWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взяття направлення в роботу"])}
  },
  "errors": {
    "codes": {
      "NOT_AUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка авторизації. Спробуйте заново увійти в систему"])},
      "NOT_WHITELISTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даний користувач не має прав на користування системою"])},
      "WRONG_CREDENTIALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірна адреса електронної скриньки або пароль"])},
      "INVALID_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка при опрацюванні даного документа"])},
      "PATIENT_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пацієнта не знайдено в базі даних"])},
      "UNEXPECTED_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невідома помилка. Зверніться до служби підтримки"])}
    },
    "fields": {
      "patient": {
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Імʼя пацієнта"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата народження"])}
      },
      "admissionDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата надходження"])},
      "dischargeDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата виписки"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата консультації"])},
      "conditions": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основний діагноз"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Супутнє/ускладнення"])}
      },
      "admissionReasonCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коди причин звернення"])},
      "examinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обстеження"])},
      "akmiCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коди АКМІ"])},
      "treatmentResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат лікування"])},
      "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендації"])},
      "doctorShortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лікар"])}
    },
    "failedToParseField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не вдалося прочитати значення: ", _interpolate(_named("field"))])}
  }
}